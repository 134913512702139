<script setup lang="ts">

import {mdiAccountCircle} from "@mdi/js";
import {getSmallestThumbnailUrl} from "@shopware-pwa/helpers-next";
import type {Schemas} from "~/api-types/storeApiTypes";

const props = withDefaults(defineProps<{
  user: Schemas['HwCustomerOneCrowdFieldsExtension']
  size?: string | number
  color?: string
}>(), {
  size: 40
})

const image = computed(() => {
  const timestamp = Date.now();
  const thumbnail = getSmallestThumbnailUrl(props.user.userImage)

  if (thumbnail) {
    return `${thumbnail}?&height=40&fit=crop&t=${timestamp}`
  }

  if (props.user.userImage?.url) {
    return `${props.user.userImage?.url}?&height=40&fit=crop&t=${timestamp}`;
  }

  return undefined
});

</script>

<template>
  <v-avatar v-if="image" :image="image" :size="size"/>
  <v-avatar v-else :size="size">
    <v-icon :size="size" :icon="mdiAccountCircle" :color="color"></v-icon>
  </v-avatar>
</template>
